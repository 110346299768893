import {
  IRequestOptions,
  request,
  cleanUrl,
  ArcGISRequestError,
  appendCustomParams
} from "./index.js";

export interface IRevokeTokenOptions extends IRequestOptions {
  /**
   * The token or refresh token to revoke. If a refresh token is passed all access tokens generated with that refresh token are also revoked.
   */
  token: string;

  /**
   * The portal of the tokens to revoke. Defaults to `https://www.arcgis.com/sharing/rest`.
   */
  portal?: string;

  /**
   * The client id fo the application that generated the tokens. Applications can only revoke tokens they created.
   */
  clientId: string;
}

export interface IRevokeTokenResponse {
  /**
   * Indicates if the token was revoked. A success response will also returned for invalid tokens, expired tokens or of an unsupported type of token to prevent leaking information about the provided token.
   */
  success: boolean;
}

/**
 * Revokes a token generated via any oAuth 2.0 method. `token` can be either a refresh token OR an access token. If you are using  {@linkcode ArcGISIdentityManager} you should use  {@linkcode ArcGISIdentityManager.destroy} instead. Cannot revoke API keys or tokens generated by {@linkcode ApplicationCredentialsManager}.
 *
 * See [`revokeToken`](https://developers.arcgis.com/rest/users-groups-and-items/revoke-token.htm) on the ArcGIS REST API for more details.
 */
export function revokeToken(
  requestOptions: IRevokeTokenOptions
): Promise<IRevokeTokenResponse> {
  const url = `${cleanUrl(
    requestOptions.portal || "https://www.arcgis.com/sharing/rest"
  )}/oauth2/revokeToken/`;

  const token = requestOptions.token;
  const clientId = requestOptions.clientId;

  delete requestOptions.portal;
  delete requestOptions.clientId;
  delete requestOptions.token;

  const options: IRequestOptions = {
    ...requestOptions,
    httpMethod: "POST",
    params: {
      client_id: clientId,
      auth_token: token
    }
  };

  return request(url, options).then((response) => {
    if (!response.success) {
      throw new ArcGISRequestError(
        "Unable to revoke token",
        500,
        response,
        url,
        options
      );
    }
    return response;
  });
}
